import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    estimations: [],
    loading: false,
    error: false,
};

const fetchEstimationStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const fetchEstimationSuccess = ( state, action ) => {
    return updateObject( state, {
        estimations: action.estimations,
        loading: false
    } );
};

const fetchEstimationFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FETCH_ESTIMATION_START: return fetchEstimationStart( state, action );
        case actionTypes.FETCH_ESTIMATION_FAIL: return fetchEstimationFail(state, action);
        case actionTypes.FETCH_ESTIMATION_SUCCESS: return fetchEstimationSuccess(state, action);
        
        default: return state;
    }
};

export default reducer;