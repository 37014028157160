import axios from 'axios'
import * as actionTypes from './actionTypes';

export const fetchAgentSuccess = (agents) => {
    return {
        type: actionTypes.FETCH_AGENT_SUCCESS,
        agents: agents
    };
};

export const fetchAgentFail = (error) => {
    return {
        type: actionTypes.FETCH_AGENT_FAIL,
        error: error
    };
};


export const fetchAgentStart = () => {
    return {
        type: actionTypes.FETCH_AGENT_START
    };
};

export const fetchAgents = (token) => {
    return dispatch => {
        dispatch(fetchAgentStart());

        let urlProd = "https://api.estimateur-immo.com/agent/getAll";
        let url = 'https://api.esti-immo.fr/agent/getAll';
        const header = {
            headers: {
                Authorization: 'baerer ' + token
            }
        }
        axios.get(urlProd, header).then(res => {
                dispatch(fetchAgentSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchAgentFail(err));
            });
    };
};

export const removeAgentSuccess = (agent) => {
    return {
        type: actionTypes.REMOVE_AGENT_SUCCESS,
        agent: agent // retirer du tableau
    };
}

export const removeAgent = (token, id) => {
    return dispatch => {
        dispatch(fetchAgentStart());

        let urlProd = "https://api.estimateur-immo.com/admin/delete-agent";
        let url = 'https://api.esti-immo.fr/admin/delete-agent';
        const config = {
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: {
                id: id
            }
        }
        axios.delete(urlProd, config).then(res => {
                dispatch(removeAgentSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchAgentFail(err));
            });
    };
}

export const activateDesactivateAgentSuccess = (agent) => {
    return {
        type: actionTypes.ACTIVE_AGENT_SUCCESS,
        agent: agent 
    };
}

export const activateDesactivateAgent = (token, id) => {
    return dispatch => {
        dispatch(fetchAgentStart());

        let urlProd = "https://api.estimateur-immo.com/admin/active-user";
        let url = 'https://api.esti-immo.fr/admin/active-user';
        const config = {
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: {
                agentId: id
            }
        }
        axios.post(urlProd, config).then(res => {
                dispatch(activateDesactivateAgentSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchAgentFail(err));
            });
    };
}
