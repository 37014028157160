import axios from 'axios'
import * as actionTypes from './actionTypes';

export const fetchLeadSuccess = (leads) => {
    return {
        type: actionTypes.FETCH_LEAD_SUCCESS,
        leads: leads
    };
};

export const fetchLeadFail = (error) => {
    return {
        type: actionTypes.FETCH_LEAD_FAIL,
        error: error
    };
};


export const fetchLeadStart = () => {
    return {
        type: actionTypes.FETCH_LEAD_START
    };
};

export const fetchLead = (token) => {
    return dispatch => {
        dispatch(fetchLeadStart());

        let urlProd = "https://api.estimateur-immo.com/admin/all-leads";

        let url = 'http://localhost:3004/admin/all-leads';
        const header = {
            headers: {
                Authorization: 'baerer ' + token
            }
        }
        axios.get(urlProd, header).then(res => {
            console.log(res.data);
                dispatch(fetchLeadSuccess(res.data));
            })
            .catch(err => {
                console.log(err);
                dispatch(fetchLeadFail(err));
            });
    };
};

