import axios from 'axios'
import * as actionTypes from './actionTypes';

export const sendMailFail = (error) => {
    return {
        type: actionTypes.FETCH_AGENT_FAIL,
        error: error
    };
};


export const sendMailStart = () => {
    return {
        type: actionTypes.FETCH_AGENT_START
    };
};

export const sendMailSuccess = (agent) => {
    return {
        type: actionTypes.SEND_MAIL_SUCCESS,
        agent: agent 
    };
}

export const sendMail = (token, body, subject, email, title) => {
    return dispatch => {
        dispatch(sendMailStart());

        let urlProd = "https://api.estimateur-immo.com/admin/send-mail";
        let url = 'https://api.esti-immo.fr/admin/send-mail';
        const config = {
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: {
                body: body,
                subject: subject,
                email: email,
                title: title
            }
        }
        axios.post(urlProd, config.data, config.headers).then(res => {
                dispatch(sendMailSuccess(res.data));
            })
            .catch(err => {
                dispatch(sendMailFail(err));
            });
    };
}
