import axios from 'axios'
import * as actionTypes from './actionTypes';

export const fetchEstimationSuccess = (estimations) => {
    return {
        type: actionTypes.FETCH_ESTIMATION_SUCCESS,
        estimations: estimations
    };
};

export const fetchEstimationFail = (error) => {
    return {
        type: actionTypes.FETCH_ESTIMATION_FAIL,
        error: error
    };
};


export const fetchEstimationStart = () => {
    return {
        type: actionTypes.FETCH_ESTIMATION_START
    };
};

export const fetchEstimation = (token) => {
    return dispatch => {
        dispatch(fetchEstimationStart());

        let urlProd = "https://api.estimateur-immo.com/admin/all-estimation";

        let url = 'https://api.esti-immo.fr/admin/all-estimation';
        const header = {
            headers: {
                Authorization: 'baerer ' + token
            }
        }
        axios.get(urlProd, header).then(res => {
                dispatch(fetchEstimationSuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchEstimationFail(err));
            });
    };
};

